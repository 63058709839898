.settings-nav .card {
  cursor: pointer;
}

.settings-row {
  display: grid;
  grid-template-columns: 22px 1fr 8px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding: 0.8em 1em;
  background: var(--note-bg);
  border-radius: 10px;
  cursor: pointer;
  gap: 10px;
  margin-bottom: 5px;
}

.settings-row.inner {
  padding: 0.8em 0;
  background-color: unset;
  border-radius: unset;
  margin: 0;
}

.settings-group-header {
  font-weight: 600;
  font-size: 16px;
  padding: 0.8em 1em;
  background-color: var(--note-bg);
  border-radius: 10px;
  margin-bottom: 5px;
}

.settings-row:hover,
.settings-group-header:hover {
  color: var(--highlight);
}

.align-end {
  margin-left: auto;
}

.settings-row svg {
  width: 100%;
  height: 100%;
}

.settings-group-header .collapse-icon > svg {
  width: 8px;
}
