.toaster {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
  z-index: 9999;
}

.toaster > .card {
  border: 1px solid var(--gray);
}
