.lnurl-modal .modal-body {
  padding: 0;
  max-width: 470px;
}

.lnurl-modal .lnurl-tip .pfp .avatar {
  width: 48px;
  height: 48px;
}

.lnurl-tip {
  padding: 24px 32px;
  background-color: #1b1b1b;
  border-radius: 16px;
  position: relative;
}

@media (max-width: 720px) {
  .lnurl-tip {
    padding: 12px 16px;
  }
}

.light .lnurl-tip {
  background-color: var(--note-bg);
}

.lnurl-tip h3 {
  color: var(--font-secondary-color);
  font-size: 11px;
  letter-spacing: 0.11em;
  font-weight: 600;
  line-height: 13px;
  text-transform: uppercase;
}

.lnurl-tip .close {
  position: absolute;
  top: 12px;
  right: 16px;
  color: var(--font-secondary-color);
  cursor: pointer;
}

.lnurl-tip .close:hover {
  color: var(--font-tertiary-color);
}

.lnurl-tip .lnurl-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.lnurl-tip .lnurl-header h2 {
  margin: 0;
  flex-grow: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.amounts {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.sat-amount {
  flex: 1 1 auto;
  text-align: center;
  display: inline-block;
  background-color: #2a2a2a;
  color: var(--font-color);
  padding: 12px 16px;
  border-radius: 100px;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.light .sat-amount {
  background-color: var(--gray);
}

.sat-amount:not(:last-child) {
  margin-right: 8px;
}

.sat-amount:hover {
  cursor: pointer;
}

.sat-amount.active {
  font-weight: bold;
  color: var(--note-bg);
  background-color: var(--font-color);
}

.lnurl-tip .invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lnurl-tip .invoice .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.lnurl-tip .invoice .actions .copy-action {
  margin: 10px auto;
}

.lnurl-tip .invoice .actions .wallet-action {
  width: 100%;
  height: 40px;
}

.lnurl-tip .zap-action {
  margin-top: 16px;
  width: 100%;
  height: 40px;
}

.lnurl-tip .zap-action svg {
  margin-right: 10px;
}

.lnurl-tip .zap-action-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lnurl-tip .custom-amount {
  margin-bottom: 16px;
}

.lnurl-tip .custom-amount button {
  padding: 12px 18px;
  border-radius: 100px;
}

.lnurl-tip canvas {
  border-radius: 10px;
}

.lnurl-tip .success-action .paid {
  font-size: 19px;
}

.lnurl-tip .success-action a {
  color: var(--highlight);
  font-size: 19px;
}
