.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--modal-bg-color);
  display: flex;
  justify-content: center;
  z-index: 42;
  overflow-y: auto;
}

.modal-body {
  background-color: var(--note-bg);
  padding: 10px;
  border-radius: 10px;
  width: 500px;
  border: 1px solid var(--font-tertiary-color);
  margin-top: auto;
  margin-bottom: auto;
}
