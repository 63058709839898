.tabs {
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 18px;
  white-space: nowrap;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab {
  color: var(--font-tertiary-color);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  font-weight: 600;
  font-size: 14px;
  padding: 6px 12px;
  text-align: center;
  font-feature-settings: "tnum";
}

.tab:not(:last-of-type) {
  margin-right: 8px;
}

.tab.active {
  border-color: var(--font-color);
  color: var(--font-color);
}

.tabs > div {
  cursor: pointer;
}

.tab.disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.tab:hover {
  border-color: var(--font-color);
}
