.pfp {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  text-decoration: none;
  user-select: none;
  min-width: 0;
}

.pfp .avatar-wrapper {
  margin-right: 8px;
  z-index: 2;
}

.pfp .avatar {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

a.pfp {
  text-decoration: none;
}

.pfp .username {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
}

.pfp .profile-name {
  max-width: stretch;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}

.pfp a {
  text-decoration: none;
}
