.avatar {
  border-radius: 50%;
  height: 210px;
  width: 210px;
  background-image: var(--img-url);
  border: 1px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  background-color: var(--gray);
}

.avatar[data-domain="snort.social"] {
  background-image: var(--img-url), var(--snort-gradient);
}

.avatar[data-domain="strike.army"] {
  background-image: var(--img-url), var(--strike-army-gradient);
}
