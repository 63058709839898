.new-user {
  color: var(--font-secondary-color);
}

.new-user input {
  font-size: 16px;
}

.new-user p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.new-user p > a {
  color: var(--highlight);
}

.new-user li {
  line-height: 24px;
}

.new-user li > a {
  color: var(--highlight);
}

.new-user .nip-handle {
  max-width: 120px;
}

.new-user h1 {
  color: var(--font-color);
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}

.new-user h2 {
  margin-top: 24px;
  margin-bottom: 16px;
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.new-user h3 {
  color: var(--font-color);
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
}

.new-user h4 {
  color: var(--font-secondary-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.progress-bar {
  width: 100%;
  height: 7px;
  background: var(--gray-secondary);
  border-radius: 53px;
}

.progress-bar .progress {
  height: 7px;
  background: var(--snort-gradient);
  border-radius: 53px;
}

.progress.progress-first {
  width: 20%;
}

.progress.progress-second {
  width: 50%;
}

.progress.progress-third {
  width: 75%;
}

.progress.progress-last {
  width: 95%;
}

.new-user .next-actions {
  margin-top: 32px;
  margin-bottom: 64px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.new-user .next-actions button:not(:last-child) {
  margin-right: 12px;
}

.new-user .next-actions.continue-actions {
  margin-bottom: 0;
}

.new-user > .copy {
  padding: 12px 16px;
  border: 2px dashed #222222;
  border-radius: 16px;
}

.light .new-user > .copy {
  border: 2px dashed #aaaaaa;
}

.new-user > .copy .body {
  font-size: 16px;
}
@media (max-width: 520px) {
  .new-user > .copy .body {
    font-size: 12px;
  }
}

.new-user > .copy .icon {
  margin-left: auto;
}

.new-user > .copy .icon svg {
  width: 16px;
  height: 16px;
}

.new-user input {
  width: 100%;
  max-width: 568px;
  background: #222;
  border: none;
}

@media (max-width: 720px) {
  .new-user input {
    width: calc(100vw - 40px);
  }
}

.light .new-user input {
  background: none;
}

.new-user .warning {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fc6e1e;
}

.profile-preview-nip {
  padding: 12px 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
}

.light .profile-preview-nip {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.new-user .nip-container input[type="text"] {
  width: 166px;
}

.new-user .help-text {
  margin-top: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
