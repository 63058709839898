.thread-container {
  margin: 12px 0 150px 0;
}

.thread-container .hidden-note {
  margin: 0;
  border-radius: 0;
}

.thread-root.note {
  box-shadow: none;
}

.thread-root.note > .body {
  margin-top: 8px;
  padding-left: 8px;
}

.thread-root.note > .body .text {
  font-size: 19px;
}

.thread-root.note > .footer {
  padding-left: 0;
}

.thread-root.note {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

.thread-note.note {
  border-radius: 0;
  margin-bottom: 0;
}

.light .thread-note.note.card {
  box-shadow: none;
}

.thread-container .hidden-note {
  margin: 0;
  border-radius: 0;
}

.thread-container .show-more {
  background: var(--note-bg);
  padding-left: 76px;
  width: 100%;
  text-align: left;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.subthread-container {
  position: relative;
}

.line-container {
  background: var(--note-bg);
}

.subthread-container.subthread-multi .line-container:before {
  content: "";
  position: absolute;
  left: 36px;
  top: 48px;
  border-left: 1px solid var(--gray-superdark);
  height: 100%;
}

@media (min-width: 720px) {
  .subthread-container.subthread-multi .line-container:before {
    left: 48px;
  }
}

.subthread-container.subthread-mid:not(.subthread-last) .line-container:after {
  content: "";
  position: absolute;
  left: 36px;
  top: 48px;
  border-left: 1px solid var(--gray-superdark);
  height: 100%;
}

@media (min-width: 720px) {
  .subthread-container.subthread-mid:not(.subthread-last) .line-container:after {
    left: 48px;
  }
}

.subthread-container.subthread-mid:not(.subthread-last) .line-container:after {
  content: "";
  position: absolute;
  border-left: 1px solid var(--gray-superdark);
  left: 36px;
  top: 0;
  height: 48px;
}

@media (min-width: 720px) {
  .subthread-container.subthread-mid:not(.subthread-last) .line-container:after {
    left: 48px;
  }
}

.subthread-container.subthread-last .line-container:before {
  content: "";
  position: absolute;
  border-left: 1px solid var(--gray-superdark);
  left: 36px;
  top: 0;
  height: 48px;
}

@media (min-width: 720px) {
  .subthread-container.subthread-last .line-container:before {
    left: 48px;
  }
}

.divider-container {
  background: var(--note-bg);
}

.divider {
  height: 1px;
  background: var(--gray-superdark);
  margin-left: 28px;
  margin-right: 22px;
}

.divider.divider-small {
  margin-left: 80px;
}

.thread-container .collapsed,
.thread-container .show-more-container {
  background: var(--note-bg);
  min-height: 48px;
}

.thread-note.is-last-note {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.thread-container .collapsed {
  background-color: var(--note-bg);
}

.thread-container .hidden-note {
  padding-left: 48px;
}

.thread-root.thread-root-single.note {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.thread-root.ghost-root {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
