.pill {
  color: var(--font-color);
  font-size: var(--font-size-small);
  display: inline-block;
  background-color: var(--gray-secondary);
  padding: 2px 8px;
  border-radius: 10px;
  user-select: none;
  margin: 2px 5px;
}

.pill.unread {
  background-color: var(--highlight);
  color: var(--font-color);
}

.pill:hover {
  cursor: pointer;
}

.light .pill.unread {
  color: white;
}
