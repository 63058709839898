.nts {
  display: flex;
  align-items: center;
}

.nts .avatar-wrapper {
  margin-right: 8px;
}

.nts .avatar {
  border-width: 1px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nts .avatar.clickable {
  cursor: pointer;
}

.nts a {
  text-decoration: none;
}

.nts .name {
  margin-top: -0.2em;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.nts .nip05 {
  margin: 0;
  margin-top: -0.2em;
}
