.favicon {
  width: 21px;
  height: 21px;
  border-radius: 100%;
  margin-right: 12px;
}

.relay-card {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.relay-settings {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.relay-settings svg:not(:last-child) {
  margin-right: 12px;
}
.relay-settings svg.enabled {
  color: var(--highlight);
}
.relay-settings svg.disabled {
  opacity: 0.3;
}

@media (max-width: 520px) {
  .relay-settings svg {
    width: 16px;
    height: 16px;
  }
}

.relay-url {
  font-size: 14px;
}

@media (min-width: 520px) {
  .relay-url {
    font-size: 16px;
  }
}
