.zapstr {
}

.zapstr > img {
  margin: 0 10px 0 0;
}

.zapstr audio {
  margin: 0;
  height: 2em;
}

.zapstr .pfp .avatar {
  width: 35px;
  height: 35px;
}

.zapstr .pfp .subheader {
  text-transform: capitalize;
}
