.note-creator {
  margin-bottom: 10px;
  background-color: var(--note-bg);
  border: none;
  border-radius: 10px;
  padding: 6px;
  position: relative;
}

.note-reply {
  margin: 10px;
}

.note-creator textarea {
  border: none;
  outline: none;
  resize: none;
  background-color: var(--note-bg);
  border-radius: 10px 10px 0 0;
  min-height: 100px;
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
  max-height: 210px;
}

.note-creator textarea::placeholder {
  color: var(--font-secondary-color);
  font-size: var(--font-size);
  line-height: 24px;
}

@media (min-width: 520px) {
  .note-creator textarea {
    min-height: 210px;
  }
}

@media (min-width: 720px) {
  .note-creator textarea {
    min-height: 321px;
  }
}

.note-creator.poll textarea {
  min-height: 120px;
}
.note-creator-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.note-creator .insert {
  display: flex;
  justify-content: flex-end;
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
}

.note-creator .insert > button {
  width: 48px;
  height: 36px;
  background: var(--gray-dark);
  color: white;
  border-radius: 17px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note-creator .attachment:hover {
  background: var(--font-color);
  color: var(--gray-dark);
}

.light .note-creator .attachment {
  background: var(--gray-light);
}

.light .note-creator .attachment:hover {
  background: var(--gray-dark);
  color: white;
}

.note-creator-actions button:not(:last-child) {
  margin-right: 4px;
}

.note-creator .error {
  position: absolute;
  left: 16px;
  bottom: 12px;
  color: var(--error);
  margin-right: 12px;
  font-size: 16px;
}

.note-create-button {
  width: 48px;
  height: 48px;
  background-color: var(--highlight);
  border: none;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-creator-modal .modal-body {
  background: var(--modal-bg-color);
}

.note-preview {
  word-break: break-all;
}

.note-preview-body {
  text-overflow: ellipsis;
  padding: 4px 4px 0 56px;
  font-size: 14px;
}
