.live-page {
  display: grid;
  height: calc(100% - 105px);
  padding: 24px;
  grid-template-columns: auto 350px;
  gap: 16px;
}

@media (min-width: 2000px) {
  .live-page {
    grid-template-columns: auto 450px;
  }
}

.live-page > div:nth-child(1) {
  overflow-y: auto;
}

.live-page video {
  width: 100%;
  aspect-ratio: 16/9;
}

.live-page .pill {
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: var(--font-secondary-color);
}
