.live-chat {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.live-chat > div:nth-child(1) {
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
}

.live-chat > div:nth-child(2) {
  flex-grow: 1;
  display: flex;
  gap: 16px;
  flex-direction: column-reverse;
  margin-block-end: 20px;
  overflow-y: auto;
}

.live-chat > div:nth-child(3) {
  display: flex;
  gap: 10px;
}

.live-chat .message {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.live-chat .message .name {
  display: inline-flex;
  align-items: center;
  color: var(--highlight);
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.live-chat .message .avatar {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
}
