.settings .avatar {
  width: 256px;
  height: 256px;
  background-size: cover;
  border-radius: 100%;
  cursor: pointer;
  margin-bottom: 20px;
}

.settings .banner {
  width: 300px;
  height: 150px;
  background-size: cover;
  margin-bottom: 20px;
}

.settings .image-settings {
  display: block;
  align-items: center;
  justify-content: center;
}

.settings .image-setting {
  display: flex;
  justify-content: space-between;
}

.settings .image-setting > div:first-child {
  align-self: center;
}

.avatar .edit,
.banner .edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  cursor: pointer;
  opacity: 0;
  border-radius: 100%;
}

.avatar .edit.new {
  opacity: 0.5;
}

.avatar .edit:hover {
  opacity: 0.5;
}

.settings .editor textarea {
  resize: vertical;
  max-height: 300px;
  min-height: 40px;
}

.settings .actions {
  margin-top: 16px;
}
