.modal.spotlight .modal-body {
  max-width: 100vw;
  width: unset;
}

.modal.spotlight img,
.modal.spotlight video {
  max-width: 90vw;
  max-height: 90vh;
  aspect-ratio: unset;
}

.modal.spotlight .close {
  text-align: right;
}
