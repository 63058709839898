.profile-preview {
  display: flex;
  align-items: center;
  min-height: 59px;
}

.profile-preview .pfp {
  flex: 1 1 auto;
}

.profile-preview .about {
  font-size: small;
  color: var(--font-secondary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-preview button {
  min-width: 98px;
}
